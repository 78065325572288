
.contentPanel {

  background: white;
  padding: 1rem 0;
  position: relative;
  word-break: break-word;
  background-size: 100%;
  background-repeat: repeat;
  background-position: center;

  >* {
    z-index: 1;
    position: relative;
  }

  h2 a.button {
    font-size: 2.6rem;
  }

  h3 a.button {
    font-size: 2.2rem;
  }

  h4 a.button {
    font-size: 1.2rem;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  &.commonFooter:before,
  &.bottomPanel:before {
    display: none;
  }

  a.button {
    color: white;
    background: $primary-color;
    padding: 0.2rem 1rem;
    font-weight: $weight-bold;
    border-radius: 20px;
    &.button-secondary{
      background: $link-hover-color;
      &:focus, &:hover{
        background: $primary-color;
      }  
    }
    &:focus, &:hover {
      background: $link-hover-color;
      text-decoration: none;
    }
  }
}

.contentPanel--dark {
  background: linear-gradient(rgba(#000, 1), rgba(#000, 0.7));
  color: white;
}

.contentPanel__bg {
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .contentPanel--dark &:before {
    background: rgba(#fff, 0.05);
  }
}

//override bootstrap container size
.container{
  @include media-breakpoint-up(xl){
      max-width: 1140px;
  }
}